import Axios from 'axios';
import { useState, useEffect } from 'react';
import { MEGA_API } from '@config/path';
export function useFetchMega<T>(
  url: string,
  changeElement?:
    | number
    | string
    | (number | string | boolean)[]
    | boolean[]
    | boolean,
): {
  payload: T | null;
  loading: boolean;
  error: string;
} {
  const [payload, setPayload] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const callUrl = async () => {
    try {
      const { data } = await Axios.get(`${MEGA_API}/${url}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${process.env.REACT_APP_IAM_API_KEY}`,
        },
      });
      setPayload(data);
    } catch {
      setError('something is worng!');
    }
    setLoading(false);
  };
  useEffect(() => {
    callUrl();
  }, [url, changeElement]);
  return { payload, loading, error };
}
