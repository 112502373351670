import React, {
  useState,
  useEffect,
  Dispatch,
  SetStateAction,
  useRef,
} from 'react';
import {
  Order,
  EditInput,
  PriceCalcurateArr,
  TotalCalc,
} from '@interfaces/tableCost.interfaces';
import NumberFormat from 'react-number-format';
import { roundNumberFunc, floorNumberFunc } from '@utils/common';
import { MESSAGE } from '@utils/textMessage';
import axios from 'axios';
import { SERVER_URL } from '@config/path';
import { useLoading } from '@config/loadingContext';

interface Props {
  from: string;
  totalCalc: TotalCalc;
  roundDownNow: number;
  latestColor: Order[];
  editInputs: EditInput;
  setEditInputs: Dispatch<SetStateAction<EditInput>>;
  totalPatial?: boolean;
}
export default function SPPriceTable({
  from,
  totalCalc,
  roundDownNow,
  latestColor,
  editInputs,
  setEditInputs,
  totalPatial,
}: Props) {
  const { loadingHandler } = useLoading();
  /**토큰 state*/
  /**전체선택 state*/
  const [idAll, setIdAll] = useState<string>('');
  const [positionN, setPositionN] = useState(false);
  const positionChange = () => setPositionN(!positionN);
  /**가격행 갯수 state*/
  let ipn: number = editInputs.productTypes[0].price?.length;
  let colorLength: number = latestColor[0] && latestColor[0].types.length;
  const [initialPriceLength, setInitialPriceLength] = useState(ipn);
  /**
   * 페이지 랜더링시 옵션값계산하여 새배열 생성, 수정했을때는 이전배열과 새배열 합치기
   * @param firstInputs 새배열
   * @param originInputs 이전배열
   */

  useEffect(() => loadingHandler(false), []);
  useEffect(() => document.getElementById(idAll)?.focus(), [idAll]);

  /**
   * 금액입력 onchange Action
   */
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;
    let indexA = Number(id.split('-')[1]);
    let indexB = Number(id.split('-')[2]);
    let ins = editInputs.productTypes[indexA];
    let instant = JSON.parse(JSON.stringify(ins));
    instant.price[indexB].calcValue = Number(value.replace(/,/g, ''));
    setEditInputs({
      ...editInputs,
      productTypes: [
        ...editInputs.productTypes.slice(0, indexA),
        (editInputs.productTypes[indexA] = instant),
        ...editInputs.productTypes.slice(indexA + 1),
      ],
    });
  };
  /**
   * 가격인푼에서의 키보드 액션
   * @param id 이동기준 index string
   * @param code 누른 키보드 key
   */
  const editNow = (idName: string) => {
    let indexA = Number(idName.split('-')[1]);
    let indexB = Number(idName.split('-')[2]);
    let instant = { ...editInputs.productTypes[indexA] };
    instant.price[indexB].edit = !instant.price[indexB].edit;
    setEditInputs({
      ...editInputs,
      productTypes: [
        ...editInputs.productTypes.slice(0, indexA),
        (editInputs.productTypes[indexA] = instant),
        ...editInputs.productTypes.slice(indexA + 1),
      ],
    });
  };
  const SalesChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id } = e.target;
    let indexA = Number(id.split('-')[1]);
    let indexB = Number(id.split('-')[2]);
    let NewBoolean = false;
    let ins = { ...editInputs.productTypes[indexA] };
    let instant = JSON.parse(JSON.stringify({ ...ins }));
    instant.price[indexB].sales = !instant.price[indexB].sales;
    setEditInputs({
      ...editInputs,
      productTypes: [
        ...editInputs.productTypes.slice(0, indexA),
        (editInputs.productTypes[indexA] = instant),
        ...editInputs.productTypes.slice(indexA + 1),
      ],
    });
    NewBoolean = instant.price[indexB].sales;
    let NEWproductId = editInputs._id;
    let NEWproductTypesId = editInputs.productTypes[indexA]._id;
    let NEW_id = editInputs.productTypes[indexA].price[indexB]._id;
    await axios
      .put(
        SERVER_URL +
          `/productbysite/${NEWproductId}/sales?productTypesId=${NEWproductTypesId}&priceId=${NEW_id}`,
        {
          turn: NewBoolean,
        },
      )
      .then(function (response) {
        loadingHandler(false);
        console.log(MESSAGE.SAVE);
      })
      .catch(function (error) {
        console.log('데이터 업로드에 실패 하였습니다.');
        loadingHandler(false);
      });
  };
  /** S:가격표 Length가 200이상일때 나눠서 뿌리는 로직 */
  const [displayNow, setDisplayNow] = useState<number>(0);
  const displayTotal = editInputs.productTypes.length;
  /** E:가격표 Length가 200이상일때 나눠서 뿌리는 로직 */
  /** S:가격표 Infinite Scrolling */
  const targetWrap = useRef<HTMLDivElement>(null);
  const scrollCenter = (str: string) => {
    const wrapHeight = targetWrap.current ? targetWrap.current.offsetHeight : 0;
    const wrapScrollHeight = targetWrap.current
      ? targetWrap.current.scrollHeight
      : 0;
    const wrapWidth = targetWrap.current ? targetWrap.current.offsetWidth : 0;
    const wrapScrollWidth = targetWrap.current
      ? targetWrap.current.scrollWidth
      : 0;
    if (str == 'end') {
      targetWrap.current?.scrollTo({
        top: Number(wrapScrollHeight / 2) - wrapHeight,
        left: Number(wrapScrollWidth / 2) - wrapWidth,
        behavior: 'smooth',
      });
    } else {
      targetWrap.current?.scrollTo({
        top: Number(wrapScrollHeight / 2),
        left: Number(wrapScrollWidth / 2),
        behavior: 'smooth',
      });
    }
  };
  const handleTotal = (type: string) => {
    if (displayTotal > 200) {
      loadingHandler(true);
      if (type == 'start') {
        if (displayNow > 0) {
          setDisplayNow(displayNow - 100);
          scrollCenter('start');
        }
      } else if (type == 'end') {
        if (Number(displayNow + 100) < displayTotal) {
          setDisplayNow(displayNow + 100);
          scrollCenter('end');
        }
      }
      setTimeout(() => loadingHandler(false), 500);
    }
  };
  /** E:가격표 Infinite Scrolling */
  /** S:판매여부 줄단위로 컨트롤 */
  const [salesAll, setSalesAll] = useState<boolean[]>(
    editInputs.productTypes.map((code) => {
      if (code.price.every((codeA) => !codeA.sales)) {
        return false;
      } else {
        return true;
      }
    }),
  );
  const SalesChangeAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, checked } = e.target;
    let idxNow = Number(id.split('-')[1]);
    let copy = {
      ...editInputs,
      productTypes: editInputs.productTypes.map((codeA, idxA) => {
        if (idxA === idxNow) {
          return {
            ...codeA,
            price: codeA.price.map((codeB, idxB) => {
              return { ...codeB, sales: checked };
            }),
          };
        } else {
          return codeA;
        }
      }),
    };
    setEditInputs(copy);
    let copyAll = [...salesAll];
    copyAll[idxNow] = checked;
    setSalesAll(copyAll);
  };
  /** E:판매여부 줄단위로 컨트롤 */

  const keyPressNow = (
    event: React.KeyboardEvent<HTMLElement>,
    id: string,
    code: string,
  ) => {
    let productTypes = editInputs.productTypes.length - 1;
    let inputLength = editInputs.productTypes[0].price.length - 1;
    let idA = String(id.split('-')[0]);
    let idB = Number(id.split('-')[1]);
    let idC = Number(id.split('-')[2]);
    if (code === 'NumpadEnter' || code === 'Enter') {
      if (idC < inputLength) {
        idC++;
      } else {
        alert('최하단입니다.');
        return;
      }
    } else if (code === 'ArrowUp') {
      if (!event.shiftKey) {
        if (idC > 0) {
          idC--;
        } else {
          alert('최상단입니다.');
          return;
        }
      }
    } else if (code === 'ArrowDown') {
      if (!event.shiftKey) {
        if (idC < inputLength) {
          idC++;
        } else {
          alert('최하단입니다.');
          return;
        }
      }
    } else if (code === 'ArrowLeft') {
      if (!event.shiftKey) {
        if (idB > 0) {
          idB--;
        } else {
          alert('첫 가격표입니다.');
        }
      }
    } else if (code === 'ArrowRight') {
      if (!event.shiftKey) {
        if (idB < productTypes) {
          idB++;
        } else {
          alert('마지막 가격표입니다.');
        }
      }
    }
    setIdAll(idA + '-' + idB + '-' + idC);
  };
  /** 복사 state */
  const [copiedArray, setCopiedArray] = useState<PriceCalcurateArr[]>([]);
  useEffect(() => {
    copiedArray.length > 0 && console.log('카피데이터있음');
  }, [copiedArray]);
  /**
   * 복사 액션
   * @param n 복사된 index string
   */
  const priceCopy = async (n: string) => {
    loadingHandler(true);
    let indexCopy = Number(n.split('-')[1]);
    let copyData = await editInputs.productTypes[indexCopy];
    if (copyData) {
      setCopiedArray([copyData]);
      alert('데이터 복사성공!');
    } else {
      alert('데이터 복사에 실패하였습니다. 다시 복사버튼을 눌러주세요.');
    }
    loadingHandler(false);
  };
  /**
   * 붙여넣기 액션
   * @param n 붙여넣을 index string
   * @constant firstInputs
   */
  const pricePaste = (n: string) => {
    loadingHandler(true);
    let indexCopy = Number(n.split('-')[1]);
    let copy = editInputs.productTypes;
    copy[indexCopy].price = copy[indexCopy].price.map((code, idx) => {
      return { ...code, calcValue: copiedArray[0].price[idx].calcValue };
    });
    let NewCopy = JSON.parse(JSON.stringify(copy)); //Deep copy
    NewCopy &&
      setEditInputs({
        ...editInputs,
        productTypes: NewCopy,
      });
    loadingHandler(false);
  };
  /**
   * 리셋 액션
   * @param n 붙여넣을 index string
   * @constant firstInputs
   */
  const priceReset = (n: string) => {
    let indexCopy = Number(n.split('-')[1]);
    let copy = editInputs.productTypes;
    copy[indexCopy].price.forEach((b) => {
      return (b.calcValue = 0);
    });

    let NewCopy = JSON.parse(JSON.stringify(copy)); //Deep copy
    NewCopy &&
      setEditInputs({
        ...editInputs,
        productTypes: NewCopy,
      });
  };
  return (
    <>
      {positionN && <div className="position-bg"></div>}
      <div className={`position-wrap ${positionN ? 'fixed' : ''}`}>
        <div className="table-position-btn" onClick={() => positionChange()}>
          <i className={positionN ? 'fa fa-compress' : 'fa fa-expand'}></i>
        </div>
        <div className="price-table-wrap" ref={targetWrap}>
          <div className="pr-block-outer">
            {editInputs.productTypes.length > 1 &&
              editInputs.productTypes.map((codeA, idxA) => {
                if (idxA >= displayNow && idxA < Number(displayNow + 200)) {
                  return (
                    <div className="pr-block-wrap" key={'selectors-' + idxA}>
                      {codeA.selectors.map((codeB, idxB) => {
                        if (idxB < codeA.selectors.length - 1) {
                          return (
                            <div
                              className={`pr-block ${idxB == 0 ? 'blue' : ''}`}
                              key={'selectors-' + idxA + '-' + idxB}
                            >
                              <p>
                                <span>{codeB.title}</span>
                              </p>
                            </div>
                          );
                        } else {
                          return (
                            <div
                              className="pr-table-wrap"
                              key={'selectors-' + idxA + '-' + idxB}
                            >
                              <table className="table table-striped yTable">
                                <thead>
                                  <tr>
                                    {from === 'setPrice' &&
                                      editInputs.totalPriceCalc !==
                                        'needSetting' && (
                                        <th style={{ padding: '0 0 2px 5px' }}>
                                          {' '}
                                          <input
                                            type="checkbox"
                                            id={'salesAll-' + idxA}
                                            checked={salesAll[idxA]}
                                            onChange={(e) => SalesChangeAll(e)}
                                          />
                                        </th>
                                      )}
                                    <th>수량</th>

                                    <th>{codeB.title + ' 가격'}</th>

                                    {((totalPatial &&
                                      editInputs.totalPriceCalc?.startsWith(
                                        'total',
                                      )) ||
                                      editInputs.totalPriceCalc ===
                                        'partial') && <th>개별금액</th>}
                                    {(editInputs.totalPriceCalc === 'partial' ||
                                      editInputs.totalPriceCalc?.startsWith(
                                        'total',
                                      )) && <th>적용금액</th>}
                                  </tr>
                                </thead>
                                <tbody>
                                  {codeA.price.map((codeC, idxP) => {
                                    return (
                                      <tr
                                        key={
                                          'tr-' + idxA + '-' + idxB + '-' + idxP
                                        }
                                      >
                                        {from === 'setPrice' &&
                                          editInputs.totalPriceCalc !==
                                            'needSetting' && (
                                            <td>
                                              <input
                                                type="checkbox"
                                                id={
                                                  'sales-' + idxA + '-' + idxP
                                                }
                                                checked={
                                                  editInputs.productTypes[idxA]
                                                    .price[idxP].sales
                                                    ? true
                                                    : false
                                                }
                                                onChange={(e) => SalesChange(e)}
                                              />
                                            </td>
                                          )}
                                        <td>
                                          <div
                                            id={'qty-' + idxA + '-' + idxP}
                                            className="qty-input"
                                            name="quantity"
                                          >
                                            {Number(
                                              editInputs.productTypes[idxA]
                                                .price[idxP].quantity,
                                            ).toLocaleString()}
                                          </div>
                                        </td>
                                        <td>
                                          <div
                                            id={'val-' + idxA + '-' + idxP}
                                            className="val-input"
                                            name="value"
                                          >
                                            {(editInputs.productTypes[idxA] &&
                                              editInputs.productTypes[
                                                idxA
                                              ].price[
                                                idxP
                                              ].value?.toLocaleString()) ||
                                              '금액없음'}
                                          </div>
                                        </td>
                                        {((totalPatial &&
                                          editInputs.totalPriceCalc?.startsWith(
                                            'total',
                                          )) ||
                                          editInputs.totalPriceCalc ===
                                            'partial') && (
                                          <td>
                                            <NumberFormat
                                              id={
                                                'editNow-' + idxA + '-' + idxP
                                              }
                                              className="val-input fakeActive"
                                              name="calcValue"
                                              value={
                                                editInputs.productTypes[idxA]
                                                  .price[idxP].calcValue != 0
                                                  ? editInputs.productTypes[
                                                      idxA
                                                    ].price[idxP].calcValue
                                                  : ''
                                              }
                                              onChange={(
                                                e: React.ChangeEvent<HTMLInputElement>,
                                              ) => handleChange(e)}
                                              onKeyDown={(
                                                event: React.KeyboardEvent<HTMLElement>,
                                              ) => {
                                                return keyPressNow(
                                                  event,
                                                  (event.target as HTMLElement)
                                                    .id,
                                                  event.code,
                                                );
                                              }}
                                              autoComplete="off"
                                              thousandSeparator
                                            />
                                          </td>
                                        )}
                                        {editInputs.totalPriceCalc?.startsWith(
                                          'total',
                                        ) && (
                                          <td>
                                            {editInputs.productTypes[idxA]
                                              .price[idxP].value ? (
                                              <span className="blue">
                                                {editInputs.productTypes[idxA]
                                                  .price[idxP].value
                                                  ? totalCalc.unit === '%'
                                                    ? floorNumberFunc(
                                                        Number(
                                                          editInputs
                                                            .productTypes[idxA]
                                                            .price[idxP].value,
                                                        ) +
                                                          (Number(
                                                            editInputs
                                                              .productTypes[
                                                              idxA
                                                            ].price[idxP].value,
                                                          ) *
                                                            Number(
                                                              totalCalc.amount,
                                                            )) /
                                                            100 +
                                                          Number(
                                                            editInputs
                                                              .productTypes[
                                                              idxA
                                                            ].price[idxP]
                                                              .calcValue
                                                              ? editInputs
                                                                  .productTypes[
                                                                  idxA
                                                                ].price[idxP]
                                                                  .calcValue
                                                              : 0,
                                                          ),
                                                        roundDownNow,
                                                      ).toLocaleString()
                                                    : floorNumberFunc(
                                                        Number(
                                                          editInputs
                                                            .productTypes[idxA]
                                                            .price[idxP].value,
                                                        ) +
                                                          Number(
                                                            totalCalc.amount,
                                                          ) +
                                                          Number(
                                                            editInputs
                                                              .productTypes[
                                                              idxA
                                                            ].price[idxP]
                                                              .calcValue
                                                              ? editInputs
                                                                  .productTypes[
                                                                  idxA
                                                                ].price[idxP]
                                                                  .calcValue
                                                              : 0,
                                                          ),
                                                        roundDownNow,
                                                      ).toLocaleString()
                                                  : 0}
                                              </span>
                                            ) : (
                                              <span className="blue">...</span>
                                            )}
                                          </td>
                                        )}
                                        {editInputs.totalPriceCalc ===
                                          'partial' && (
                                          <td className="blue">
                                            {roundNumberFunc(
                                              Number(
                                                editInputs.productTypes[idxA]
                                                  .price[idxP].value,
                                              ) +
                                                Number(
                                                  editInputs.productTypes[idxA]
                                                    .price[idxP].calcValue
                                                    ? editInputs.productTypes[
                                                        idxA
                                                      ].price[idxP].calcValue
                                                    : 0,
                                                ),
                                            ).toLocaleString()}
                                          </td>
                                        )}
                                      </tr>
                                    );
                                  })}
                                  <tr>
                                    <td colSpan={5}>
                                      <div
                                        className="price-tools"
                                        id={'copyBtn-' + String(idxA)}
                                        onClick={(
                                          event: React.MouseEvent<HTMLElement>,
                                        ) =>
                                          priceCopy(
                                            (event.target as HTMLElement).id,
                                          )
                                        }
                                      >
                                        <i className="fa fa-clone"></i> 복사
                                      </div>
                                      <div
                                        id={'pasteBtn-' + String(idxA)}
                                        className="price-tools"
                                        onClick={(
                                          event: React.MouseEvent<HTMLElement>,
                                        ) =>
                                          pricePaste(
                                            (event.target as HTMLElement).id,
                                          )
                                        }
                                      >
                                        <i className="fa fa-paste"></i> 붙여넣기
                                      </div>
                                      <div
                                        id={'resetBtn-' + String(idxA)}
                                        className="price-tools"
                                        onClick={(
                                          event: React.MouseEvent<HTMLElement>,
                                        ) =>
                                          priceReset(
                                            (event.target as HTMLElement).id,
                                          )
                                        }
                                      >
                                        <i className="fa fa-undo"></i> 초기화
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          );
                        }
                      })}
                    </div>
                  );
                } else {
                  return;
                }
              })}
          </div>
        </div>
        <div className="add-input-length text-center">
          {positionN && (
            <div className="floating-Btn pull-right">
              <div
                className="btn yBtn yBtn-small grey btn-inline-block mt-3 ms-2"
                data-toggle="tooltip"
                onClick={() => positionChange()}
              >
                닫기
              </div>
            </div>
          )}
        </div>
        {displayTotal > 200 && (
          <div className="price-table-filter-wrap">
            <div className="price-table-filter-total">
              (현재 {displayNow + 1}~ / 총 {displayTotal})
              <div className="total-btn-wrap">
                <div
                  className="line-tools"
                  onClick={() => handleTotal('start')}
                >
                  이전 <i className="fa fa-caret-up"></i>
                </div>
                <div className="line-tools" onClick={() => handleTotal('end')}>
                  다음 <i className="fa fa-caret-down"></i>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
