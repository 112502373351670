export const MEGA_API = 'https://admin.printdeal.co.kr';
export const MEGACodeUrl = 'https://admin.printdeal.co.kr/api/codeApi/codes';
export const MEGAProductsUrl =
  'https://admin.printdeal.co.kr/api/codeApi/products';
export const IFRAME_URL = 'https://d3h2ch30mblld0.cloudfront.net';
//'http://localhost:3002';

/**
 * INFO: 로컬에서 실행시 npm start 하면 실제 운용되고 있는 서버에서 실행시키고 npm run dev에서 실행하면 로컬에서 서버실행함
 */

export const SERVER_URL =
  process.env.REACT_APP_NODE_ENV === 'development'
    ? 'http://localhost:8080'
    : process.env.REACT_APP_NODE_ENV === 'test'
    ? 'https://price-api-test.dtp21.com'
    : 'https://price-api.dtp21.com/v2';

// 'https://602e-218-152-33-66.ngrok.io';

export const NEST_SERVER_URL =
  process.env.REACT_APP_NODE_ENV === 'development'
    ? 'http://localhost:8080'
    : process.env.REACT_APP_NODE_ENV === 'test'
    ? 'https://price-api-test.dtp21.com'
    : 'https://price-api.dtp21.com/v2';
