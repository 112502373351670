import { useState, useEffect } from 'react';
import axios from 'axios';
import useFetch from '@utils/customHook/useFetch';
import { SERVER_URL } from '@config/path';
import { FirstInput, ProductListType } from '@interfaces/tableCost.interfaces';
import { Site, AutoDiscountType } from '@interfaces/site.interface';
import { useAuthState } from '@config/context';
import { basicConst } from '@dummydata/dummy';
import SPPriceModal from '@components/modal/productmanager/price-modal-inner';
import SPAutoDiscount from '@components/modal/productmanager/auto-modal-inner';
import SPNameModal from '@components/modal/productmanager/name-modal-inner';
import SPOptionModal from '@components/modal/productmanager/option-modal-inner';
import UpdateModal from '@components/modal/productmanager/update-modal-inner';
import PreviewModal from '@components/modal/previewModal';
import SetPriceCalc from '@pages/productmanager/setprice-calc';
import { CONFIRM, MESSAGE } from '@utils/textMessage';
import { useLoading } from '@config/loadingContext';
import DeleteModal from '@components/modal/deleteModal';
import moment from 'moment';
interface Props {
  selectedSite: Site;
  sellerNameEN: string;
  GPmodalhandler: (boolean: boolean) => void;
}
export default function SetPriceInner({
  selectedSite,
  sellerNameEN,
  GPmodalhandler,
}: Props) {
  moment.locale();
  const { loadingNow, loadingHandler } = useLoading();
  const { payload, loading, error } = useFetch<ProductListType>(
    `productbysite?target=${sellerNameEN}`,
    loadingNow,
  );
  const {
    payload: autoPayload,
    loading: autoLoading,
    error: autoError,
  } = useFetch<AutoDiscountType>(`discount`, loadingNow);
  /**S:제품필터 */
  const autoRelated = autoPayload
    ? autoPayload.data.map((a) => {
        return a.relatedProducts[0];
      })
    : [];
  const [productList, setProductList] = useState<ProductListType[]>([]);
  // const [sortedProducts, setSortedProducts] = useState<ProductListType[]>([]);
  const [typeFilter, setTypeFilter] = useState<string[]>();
  const [typeFilterNow, setTypeFilterNow] = useState<string>('전체');
  const [productFilter, setProductFilter] = useState<string[]>();
  const [productFilterNow, setProductFilterNow] = useState<string>('전체');
  const [selectedAuto, setSelectedAuto] = useState<AutoDiscountType>();
  useEffect(() => {
    if (payload) {
      let copy = [...payload.data];
      setProductList(copy);
      let filt = copy
        .filter((a) => a.category[0].categoryName1st)
        .map((b) => {
          return b.category[0].categoryName1st;
        })
        .filter(
          (thing: string, index: number, self: string[]) =>
            index === self.findIndex((t) => t === thing),
        );
      setProductFilter(filt);
      let typefilt = copy
        .filter((a) => a.typeOfPriceDetermintion)
        .map((b) => {
          return b.typeOfPriceDetermintion;
        })
        .filter(
          (thing: string, index: number, self: string[]) =>
            index === self.findIndex((t) => t === thing),
        );
      setTypeFilter(typefilt);
      let dataA = changeProductFilter('type', typeFilterNow, copy);
      let dataB = changeProductFilter('group', productFilterNow, dataA);
      nsortBy(nsortNow.type, nsortNow.key, dataB);
    } else {
      console.log('payload yet!');
    }
  }, [payload]);
  const changeProductFilter = (
    kind: string,
    value: string,
    data: ProductListType[],
  ) => {
    let typeNow = kind == 'type' ? value : typeFilterNow;
    let groupNow = kind == 'group' ? value : productFilterNow;
    let copy = [...data];
    if (kind === 'type') {
      copy = copy.filter((a) =>
        typeNow !== '전체' ? a.typeOfPriceDetermintion === typeNow : a,
      );
      setProductList(copy);
      let filt = copy
        .filter((a) => a.category[0].categoryName1st)
        .map((b) => {
          return b.category[0].categoryName1st;
        })
        .filter(
          (thing: string, index: number, self: string[]) =>
            index === self.findIndex((t) => t === thing),
        );
      setProductFilter(filt);
      setTypeFilterNow(typeNow);
      setProductFilterNow('전체');
    } else {
      copy = copy
        .filter((a) =>
          groupNow !== '전체' ? a.category[0].categoryName1st == groupNow : a,
        )
        .filter((a) =>
          typeNow !== '전체' ? a.typeOfPriceDetermintion === typeNow : a,
        );
      setProductList(copy);
      setProductFilterNow(groupNow);
    }
    return copy;
  };
  /**E:제품필터 */

  /** S:모달 state*/
  const [modal, setModal] = useState<{ [index: string]: boolean }>({
    autoDiscount: false,
    price: false,
    option: false,
    update: false,
    calc: false,
    preview: false,
    delete: false,
  });
  const [titleChange, setTitleChange] = useState<string | null>(null);
  const changeModal = (key: string, bool: boolean) => {
    setModal({ ...modal, [key]: bool });
  };
  const changeName = (id: string) => {
    setTitleChange(id);
  };
  const closeName = () => {
    setTitleChange(null);
  };
  /** E:모달 state*/
  const siteName = selectedSite.sellerName;
  const siteNameEN = selectedSite.sellerNameEN;
  let basicCopy = JSON.parse(JSON.stringify(basicConst));
  const [firstInputs, setFirstInputs] = useState<FirstInput>({
    ...basicCopy,
  });
  /**미리보기모달 state*/
  const [previewName, setPreviewName] = useState<string>('');
  const previewhandler = (bool: boolean) =>
    setModal({ ...modal, preview: bool });
  /**
   * 판매중 변경 함수
   * @param productNameEN
   */
  // const SalesHandler = async (index: number, bool: boolean) => {
  //   loadingHandler(true);
  //   let copyList = [...productList];
  //   copyList[index].saleStatus = bool;
  //   setProductList(copyList);
  //   await axios
  //     .put(
  //       SERVER_URL +
  //         '/productbysite/productsales?productId=' +
  //         copyList[index]._id +
  //         '&turn=' +
  //         bool,
  //     )
  //     .then(function (response) {
  //       loadingHandler(false);
  //       console.log(MESSAGE.SAVE);
  //     })
  //     .catch(function (error) {
  //       console.log('데이터 업로드에 실패 하였습니다.');
  //       loadingHandler(false);
  //     });
  // };

  /**
   * 업데이트로그 함수
   * @param productNameEN
   */
  const UpdateLog = (productNameEN: string) => {
    console.log('productNameEN', productNameEN);
  };
  /**
   * 기준 상품 프리뷰 오픈
   * @param productNameEN
   */
  const PreviewEvent = (productID: string) => {
    setPreviewName(productID);
    loadingHandler(true);
    changeModal('preview', true);
  };

  /**
   * 가격, 옵션명 수정하는 함수
   * @param type 이벤트종류
   * @param TARGET 사이트
   * @param PRODUCT 제품
   */
  const getSelected = async (
    type: string,
    productBySiteId: string,
    TARGET: string,
    PRODUCT: string,
    idx?: number,
  ) => {
    loadingHandler(true);
    await axios
      .get(`${SERVER_URL}/productbysite/${productBySiteId}`) //.get(`${SERVER_URL}/productbysite?target=${TARGET}&product=${PRODUCT}`)
      .then((res) => {
        setFirstInputs({ ...res.data.data }); //데이터 업데이트
        setPreviewName(PRODUCT);
        if (type !== 'title') {
          changeModal(type, true);
        } else {
          changeName(productBySiteId);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    loadingHandler(false);
  };

  /** 계산식용 ID state */
  const [productName, setProductName] = useState<string>('');
  const [calcProductId, setCalcProductId] = useState<string>('');
  /**
   * 계산식 변경 함수
   * @param productNameEN
   */
  const calcSelected = async (ID: string, Name: string) => {
    loadingHandler(true);
    setCalcProductId(ID);
    setProductName(Name);
    changeModal('calc', true);
    loadingHandler(false);
  };

  /** 자동점검 할인율 state */
  const [autoProductId, setAutoProductId] = useState<string>('');
  const [autoProductType, setAutoProductType] = useState<string>('');
  /**
   * 계산식 변경 함수
   * @param productNameEN
   */
  const openAutoDiscount = async (
    id: string,
    target: string,
    product: string,
    type: string,
  ) => {
    loadingHandler(true);
    await axios
      .get(`${SERVER_URL}/productbysite?target=${target}&product=${product}`)
      .then((res) => {
        setFirstInputs(res.data.data[0]); //데이터 업데이트
        setAutoProductId(id);
        setAutoProductType(type);
        if (autoRelated.includes(id)) {
          let copy = autoPayload
            ? autoPayload.data.filter((code) =>
                code.relatedProducts.includes(id),
              )[0]
            : null;
          copy !== null && setSelectedAuto(copy);
        }
        changeModal('autoDiscount', true);
      })
      .catch((err) => {
        console.log(err);
      });
    loadingHandler(false);
  };

  /**
   * 사이트에서 제품 삭제하는 함수
   * @param productNameEN
   */
  const DelEvent = async (idNow: string) => {
    loadingHandler(true);
    confirm(CONFIRM.DEL)
      ? await axios
          .delete(`${SERVER_URL}/productbysite/${idNow}`)
          .then((res) => {
            alert(MESSAGE.DELDONE);
          })
          .catch((err) => {
            alert(MESSAGE.CONFIRMAUTH);
            console.log(err);
          })
      : '';
    loadingHandler(false);
  };
  /**
   * 사이트에서 제품 기준가격 업데이트하는 함수
   * @param productNameEN
   */
  const [updateInputs, setUdpateInputs] = useState<FirstInput>();
  const UpdateEvent = async (TARGET: string, PRODUCT: string) => {
    loadingHandler(true);
    confirm(CONFIRM.UPDATE) &&
      (await axios
        .get(`${SERVER_URL}/productbysite?target=${TARGET}&product=${PRODUCT}`)
        .then((res) => {
          setFirstInputs(res.data.data[0]); //데이터 업데이트
          setPreviewName(PRODUCT);
          axios
            .get(`${SERVER_URL}/product/productNameEN/${PRODUCT}`)
            .then((response) => {
              setUdpateInputs(response.data); //기준가격 load
              changeModal('update', true);
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
        }));
    loadingHandler(false);
  };

  /** 테이블 sort 화살표 색상 */

  const [sortColor, setSortColor] = useState<string>('');

  /**테이블 Sort State */

  const [nsortNow, setNsortNow] = useState<{ type: boolean; key: string }>({
    type: false,
    key: 'createdAt',
  });

  /**테이블 Sort Action */

  const [sortselect, setsortselect] = useState<boolean>(true);

  const nsortBy = (type: boolean, key: string, data: ProductListType[]) => {
    setsortselect(!sortselect);
    setSortColor(String([key]));
    let copy = [...data];
    if (key !== 'categoryName1st') {
      copy.sort((a, b) =>
        !type
          ? String(a[key]) < String(b[key])
            ? -1
            : 1
          : String(a[key]) > String(b[key])
          ? -1
          : 1,
      );
    } else {
      copy.sort((a, b) =>
        !type
          ? a.category[0].categoryName1st < b.category[0].categoryName1st
            ? -1
            : 1
          : a.category[0].categoryName1st > b.category[0].categoryName1st
          ? -1
          : 1,
      );
    }
    setProductList(copy);
    setNsortNow({ ...nsortNow, type: type, key: key });
  };

  /** 딜리트 모달 */

  const [delID, setDelID] = useState<string>();
  const openDelConfirm = (id: string) => {
    setDelID(id);
    changeModal('delete', true);
  };

  /** 텍스트 복사 */

  const handleCopyClipBoard = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
      alert('클립보드에 링크가 복사되었습니다.');
    } catch (e) {
      alert('복사에 실패하였습니다');
    }
  };
  return (
    <>
      <div className="fixedContainer bg-f7f7f7">
        <div className="page-title">
          <div className="title_left">
            <h3>
              <i className="fa fa-cubes" />
              {selectedSite.sellerName} 상품 리스트 <small>사이트별 상품</small>
              <div className="pull-right">
                <button
                  className="goBackBtn"
                  onClick={() => calcSelected('', '전체')}
                >
                  {selectedSite.sellerName} 계산식단가보기
                </button>
                <button
                  className="goBackBtn"
                  onClick={() => GPmodalhandler(false)}
                >
                  이전으로
                </button>
              </div>
            </h3>
          </div>
        </div>
        <div className="clearfix"></div>
        {payload && (
          <div className="row">
            <div className="col-12">
              <div className="contents-wrap x_panel mb-2">
                <div className="groupFilter">
                  <h5>가격표타입</h5>
                  <div className="groupFilter-wrap">
                    <div className="groupFilter-inner">
                      <div
                        className={`groupFilter-item ${
                          typeFilterNow == '전체' ? 'active' : ''
                        }`}
                        onClick={() =>
                          changeProductFilter('type', '전체', payload.data)
                        }
                      >
                        전체
                      </div>
                      {typeFilter?.map((filt) => {
                        return (
                          <div
                            key={filt}
                            className={`groupFilter-item ${
                              typeFilterNow == filt ? 'active' : ''
                            }`}
                            onClick={() =>
                              changeProductFilter('type', filt, payload.data)
                            }
                          >
                            {filt == 'priceComplete' ? '완성형' : '계산형'}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
                <div className="groupFilter">
                  <h5>제품그룹</h5>
                  <div className="groupFilter-wrap red">
                    <div className="groupFilter-inner">
                      <div
                        className={`groupFilter-item ${
                          productFilterNow == '전체' ? 'active' : ''
                        }`}
                        onClick={() =>
                          changeProductFilter('group', '전체', payload.data)
                        }
                      >
                        전체
                      </div>
                      {productFilter?.map((filt) => {
                        return (
                          <div
                            key={filt}
                            className={`groupFilter-item ${
                              productFilterNow == filt ? 'active' : ''
                            }`}
                            onClick={() =>
                              changeProductFilter('group', filt, payload.data)
                            }
                          >
                            {filt}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
              <div className="clearfix"></div>
              <div className="contents-wrap x_panel">
                <div className="x_content setprice-table-wrap setting">
                  <div className="react-bootstrap-table">
                    <table className="table table-hover table-bordered">
                      <thead>
                        <tr>
                          <th
                            className={`sortable ${
                              sortColor === 'categoryName1st' ? 'colorOn' : ''
                            }`}
                            onClick={() => {
                              nsortBy(
                                !sortselect,
                                'categoryName1st',
                                productList,
                              );
                            }}
                          >
                            분류{' '}
                            <span
                              className={`order-4 ${
                                nsortNow.type === false ? 'setcolor' : 'nocolor'
                              }`}
                            ></span>
                          </th>
                          <th
                            className={`sortable cont-w150 ${
                              sortColor === 'productNameKO' ? 'colorOn' : ''
                            }`}
                            onClick={() => {
                              nsortBy(
                                !sortselect,
                                'productNameKO',
                                productList,
                              );
                            }}
                          >
                            제품명{' '}
                            <span
                              className={`order-4 ${
                                nsortNow.type === false ? 'setcolor' : 'nocolor'
                              }`}
                            ></span>
                          </th>
                          <th
                            className={`sortable cont-w150 ${
                              sortColor === 'productNameEN' ? 'colorOn' : ''
                            }`}
                            onClick={() => {
                              nsortBy(
                                !sortselect,
                                'productNameEN',
                                productList,
                              );
                            }}
                          >
                            영문제품명{' '}
                            <span
                              className={`order-4 ${
                                nsortNow.type === false ? 'setcolor' : 'nocolor'
                              }`}
                            ></span>
                          </th>
                          {/* <th
                          className="sortable"
                          onClick={() => {
                            sortBy('saleStatus');
                          }}
                        >
                          판매여부 <span className="order-4"></span>
                        </th> */}
                          <th
                            className={`sortable ${
                              sortColor === 'totalPriceCalc' ? 'colorOn' : ''
                            }`}
                            onClick={() => {
                              nsortBy(
                                !sortselect,
                                'totalPriceCalc',
                                productList,
                              );
                            }}
                          >
                            기본판매가{' '}
                            <span
                              className={`order-4 ${
                                nsortNow.type === false ? 'setcolor' : 'nocolor'
                              }`}
                            ></span>
                          </th>
                          <th className="">코드 보기</th>
                          <th
                            className={`sortable ${
                              sortColor === 'createdAt' ? 'colorOn' : ''
                            }`}
                            onClick={() => {
                              nsortBy(!sortselect, 'createdAt', productList);
                            }}
                          >
                            생성/수정{' '}
                            <span
                              className={`order-4 ${
                                nsortNow.type === false ? 'setcolor' : 'nocolor'
                              }`}
                            ></span>
                          </th>
                          <th>ACTION</th>
                        </tr>
                      </thead>
                      <tbody>
                        {productList.map((code, idx) => {
                          let time = moment(code.createdAt).format(
                            'YYYY-MM-DD (HH:mm)',
                          );
                          let time2 = moment(code.modifiedAt).format(
                            'YYYY-MM-DD (HH:mm)',
                          );
                          return (
                            <tr key={idx}>
                              <td>
                                {code.category[0].categoryName1st +
                                  ' / ' +
                                  code.category[0].categoryName2nd}
                              </td>
                              <td className="position-relative">
                                <div className="d-inline-block me-2">
                                  {code.productNameKO}
                                </div>
                                <button
                                  className="nameChange-btn blue"
                                  onClick={() =>
                                    getSelected(
                                      'title',
                                      code._id,
                                      sellerNameEN,
                                      code.productNameEN,
                                      idx,
                                    )
                                  }
                                >
                                  <i
                                    className="fa fa-pencil"
                                    aria-hidden="true"
                                  ></i>
                                </button>
                                {titleChange && titleChange === code._id && (
                                  <SPNameModal
                                    indexNow={idx}
                                    idNow={code._id}
                                    valueNow={code.productNameKO}
                                    closeName={closeName}
                                  />
                                )}
                              </td>
                              <td>{code.productNameEN}</td>
                              {/* <td>
                              <label className="salesSwitch">
                                <input
                                  type="checkbox"
                                  checked={code.saleStatus ? true : false}
                                  onChange={() =>
                                    SalesHandler(idx, !code.saleStatus)
                                  }
                                />
                                <span className="slider round"></span>
                                <p className="pNow">
                                  {!code.saleStatus ? '판매중지' : '판매중'}
                                </p>
                              </label>
                            </td> */}
                              <td>
                                <div>
                                  {code.totalPriceCalc === 'needSetting'
                                    ? '세팅전'
                                    : code.totalPriceCalc === 'standard'
                                    ? '기본가격 적용'
                                    : code.totalPriceCalc === 'partial'
                                    ? '부분가격 변경'
                                    : '전체가격 변경'}
                                </div>
                              </td>
                              <td>
                                <div className="editDate-wrap editCode-wrap">
                                  <div className="editDate-btn">보기</div>
                                  <div className="editDate-view">
                                    <div className="editDate-line">
                                      <h5>MP코드 :</h5>
                                      <div
                                        className="editData-copy"
                                        onClick={() => {
                                          handleCopyClipBoard(
                                            code.category[0].categoryName3rd,
                                          );
                                        }}
                                      >
                                        <p className="standardproductID editCod-Id">
                                          {code.category[0].categoryName3rd}
                                        </p>
                                        <p className="standardproductTIME">
                                          복사
                                        </p>
                                      </div>
                                    </div>
                                    <div className="editDate-line">
                                      <h5>제품ID :</h5>
                                      <div
                                        className="editData-copy"
                                        onClick={() => {
                                          handleCopyClipBoard(code._id);
                                        }}
                                      >
                                        <p className="standardproductID editCod-Id">
                                          {code._id}
                                        </p>
                                        <p className="standardproductTIME">
                                          복사
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </td>

                              <td>
                                <div className="editDate-wrap">
                                  <div className="editDate-btn">보기</div>
                                  <div className="editDate-view">
                                    <div className="editDate-line">
                                      <h5>생성:</h5>
                                      <p className="standardproductID">
                                        {code.createdBy}
                                      </p>
                                      <p className="standardproductTIME">
                                        {time}
                                      </p>
                                    </div>
                                    <div className="editDate-line">
                                      <h5>수정:</h5>
                                      <p className="standardproductID">
                                        {code.modifiedBy}
                                      </p>
                                      <p className="standardproductTIME">
                                        {time2}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <>
                                  {code.typeOfPriceDetermintion ==
                                  'priceComplete' ? (
                                    <button
                                      className="tableBtn tableBtn-orange btnW-100 setpriceBtn"
                                      onClick={() =>
                                        getSelected(
                                          'price',
                                          code._id,
                                          sellerNameEN,
                                          code.productNameEN,
                                        )
                                      }
                                    >
                                      <span className="mobileSpan">
                                        <i
                                          className="fa fa-won"
                                          aria-hidden="true"
                                        />
                                      </span>
                                      <span className="deskSpan">
                                        판매단가결정
                                      </span>
                                    </button>
                                  ) : (
                                    <button
                                      className="tableBtn tableBtn-pink btnW-100 setpriceBtn"
                                      onClick={() =>
                                        calcSelected(
                                          code._id,
                                          code.productNameKO,
                                        )
                                      }
                                    >
                                      <span className="mobileSpan">
                                        <i
                                          className="fa fa-won"
                                          aria-hidden="true"
                                        />
                                      </span>
                                      <span className="deskSpan">
                                        계산식단가결정
                                      </span>
                                    </button>
                                  )}
                                  {sellerNameEN === 'iamdesign' && (
                                    <button
                                      className="tableBtn tableBtn-yellow btnW-100 setpriceBtn"
                                      onClick={() =>
                                        openAutoDiscount(
                                          code._id,
                                          sellerNameEN,
                                          code.productNameEN,
                                          code.typeOfPriceDetermintion,
                                        )
                                      }
                                    >
                                      <span className="mobileSpan">
                                        <i
                                          className="fa fa-percent"
                                          aria-hidden="true"
                                        />
                                      </span>
                                      <span className="deskSpan">
                                        자동점검 할인율
                                      </span>
                                    </button>
                                  )}
                                  <button
                                    className="tableBtn tableBtn-green btnW-100 setpriceBtn"
                                    onClick={() =>
                                      getSelected(
                                        'option',
                                        code._id,
                                        sellerNameEN,
                                        code.productNameEN,
                                      )
                                    }
                                  >
                                    <span className="mobileSpan">
                                      <i
                                        className="fa fa-pencil"
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                    <span className="deskSpan">
                                      옵션수정&그룹
                                    </span>
                                  </button>
                                  <button
                                    className="tableBtn tableBtn-skyblue btnW-100 setPricePreview setpriceBtn"
                                    onClick={() => PreviewEvent(code._id)}
                                  >
                                    <span className="mobileSpan">
                                      <i
                                        className="fa fa-eye"
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                    <span className="deskSpan">미리보기</span>
                                  </button>
                                  <button
                                    className="tableBtn tableBtn-red btnW-100 setPriceDelEvents setpriceBtn"
                                    onClick={() => openDelConfirm(code._id)}
                                  >
                                    <span className="mobileSpan">
                                      <i
                                        className="fa fa-times"
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                    <span className="deskSpan">삭제하기</span>
                                  </button>

                                  <button
                                    className={`tableBtn tableBtn-${
                                      code.typeOfPriceDetermintion ==
                                      'priceComplete'
                                        ? 'purple'
                                        : 'pink'
                                    } btnW-100 setpriceBtn`}
                                    onClick={() =>
                                      UpdateEvent(
                                        sellerNameEN,
                                        code.productNameEN,
                                      )
                                    }
                                  >
                                    <span className="mobileSpan">
                                      <i
                                        className="fa fa-mixcloud"
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                    <span className="deskSpan">
                                      {code.typeOfPriceDetermintion ==
                                      'priceComplete'
                                        ? '기준가격'
                                        : '기준옵션'}{' '}
                                      업데이트
                                    </span>
                                  </button>

                                  <button
                                    className="tableBtn tableBtn-gray btnW-100 setpriceBtn"
                                    onClick={() => UpdateLog(code._id)}
                                  >
                                    <span className="mobileSpan">
                                      <i
                                        className="fa fa-file-text-o"
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                    <span className="deskSpan">
                                      업데이트 이력
                                    </span>
                                  </button>
                                </>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {modal.price && (
        <SPPriceModal
          siteName={siteName}
          firstInputs={firstInputs}
          setFirstInputs={setFirstInputs}
          changeModal={changeModal}
        />
      )}
      {modal.calc && (
        <SetPriceCalc
          siteName={siteName}
          productName={productName}
          sellerNameEN={sellerNameEN}
          calcProductId={calcProductId}
          changeModal={changeModal}
        />
      )}
      {modal.autoDiscount && (
        <SPAutoDiscount
          type={autoRelated.includes(autoProductId) ? 'edit' : 'new'}
          selectedAuto={selectedAuto}
          firstInputs={firstInputs}
          autoProductId={autoProductId}
          autoProductType={autoProductType}
          changeModal={changeModal}
        />
      )}
      {modal.option && (
        <SPOptionModal
          siteName={siteName}
          firstInputs={firstInputs}
          setFirstInputs={setFirstInputs}
          changeModal={changeModal}
        />
      )}
      {modal.update && updateInputs && (
        <UpdateModal
          siteName={siteName}
          siteNameEN={siteNameEN}
          firstInputs={firstInputs}
          setFirstInputs={setFirstInputs}
          updateInputs={updateInputs}
          changeModal={changeModal}
        />
      )}
      {modal.preview && (
        <PreviewModal
          from="site"
          previewProduct={previewName}
          previewhandler={previewhandler}
        />
      )}
      {modal.delete && delID && (
        <DeleteModal
          delID={delID}
          delEvents={DelEvent}
          changeModal={changeModal}
        />
      )}
    </>
  );
}
