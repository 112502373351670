import { useContext, createContext, useReducer } from 'react';

import { AuthReducer, initialState } from './reducer';
import { State, AuthDispatch, Pages } from '@interfaces/globals.types';
export type SidebarTypes = {
  navOpen: Pages;
  setNavOpen: (c: Pages) => void;
};
const AuthStateContext = createContext<State | undefined>(undefined);
const AuthDispatchContext = createContext<AuthDispatch | undefined>(undefined);

export function useAuthState() {
  const context = useContext(AuthStateContext);
  if (context === undefined) {
    throw new Error('useAuthState는 AuthProvider 안에서만 사용 가능합니다.');
  }

  return context;
}

export function useAuthDispatch() {
  const context = useContext(AuthDispatchContext);
  if (context === undefined) {
    throw new Error('useAuthDispatch는 AuthProvider 안에서만 사용 가능합니다.');
  }
  return context;
}

export const AuthProvider = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}): JSX.Element => {
  const [user, dispatch] = useReducer(AuthReducer, initialState);

  return (
    <AuthStateContext.Provider value={user}>
      <AuthDispatchContext.Provider value={dispatch}>
        {children}
      </AuthDispatchContext.Provider>
    </AuthStateContext.Provider>
  );
};
export const SidbarContext = createContext<SidebarTypes>({
  navOpen: 'home', // set a default value
  setNavOpen: () => ({}),
});
export const useSidbarContext = () => useContext(SidbarContext);
