interface Props {
  pushNav: () => void;
}

export default function Header({ pushNav }: Props) {
  return (
    <>
      <div className="top_nav">
        <div className="nav_menu">
          <nav>
            <div className="nav toggle">
              <button onClick={() => pushNav()} id="menu_toggle">
                <i className="fa fa-arrow-left"></i>
              </button>
            </div>
          </nav>
        </div>
      </div>
      <div className="clearfix"></div>
    </>
  );
}
