/**
 * confirm 시 띄우는 text
 */
const CONFIRM = {
  ADD: '추가 하시겠습니까?',
  SAVE: '저장하시겠습니까?',
  DEL: '정말 삭제하시겠습니까?',
  HIDE: '정말 숨기시겠습니까?',
  UPDATE: '기준가격을 업데이트 하시겠습니까?',
  DIFFERUP:
    '기준가격과 옵션이 달라 옵션명, 당일판, 사이트별가격 등을 다시 세팅하셔야합니다. 기준가격을 업데이트 하시겠습니까?',
  INIT: '이전으로 돌아갈 경우 입력한 가격표가 초기화 될 수 있습니다.',
  PATIALZERO: '설정해두신 개별금액이 0원이 됩니다. 진행하시겠습니까?',
  HIDEONLYONE: '1개 남은 옵션은 숨김이 불가 합니다.',
  DELONLYONE: '1개 남은 옵션은 삭제불가 합니다.',
  DELPERMENET:
    '옵션은 영구정으로 삭제되며,\n되살리시려면기중상품을 다시 가져와야 합니다.',
};
/**
 * alert에서 띄우는 text
 */

const MESSAGE = {
  SAVE: '저장 완료',
  COLLSION: '기존에 있는 데이터입니다. 확인해주세요.',
  SAVEPREVIEW: '비정상적인 접근입니다. 저장후에 미리보기 해주세요.',
  NAME: '상품명을 입력하세요.',
  EnNAME: '영문 상품명을 입력하세요.',
  CATEGORY: '상품 카테고리를 선택하세요.',
  OPTION: '옵션명을 입력하세요.',
  OPTIONCODE: '옵션 코드값을 선택 하세요.',
  MATERIAL: '용지 코드가 필요합니다, 용지 옵션을 추가해 주세요.',
  COL: '인쇄도수 코드가 필요합니다, 인쇄도수 옵션을 추가해 주세요.',
  OVERLAP: '중복된 옵션명이 있습니다, 한개만 남겨 주세요.',
  DELDONE: '삭제 완료',
  NOTDEL: '삭제 할수 없습니다.',
  CONFIRMAUTH: '삭제 권한이 없습니다. 관리자에게 문의하세요',
  EMPTYOPTION: '빈 주문 옵션 정보가 있습니다.',
  MORE2OPTION: '두개 이상의 옵션을 선택하여 주십시오.',
  TWOMETERIAL: '두개 이상의 용지 옵션을 선택하셨습니다.',
  CONFIRMVALUE: '모든 값을 정확하게 입력해주세요',
  LOG: '로그인 연장되었습니다.',
  TOKENEXPIRED: '토큰이 만료되었습니다. 로그아웃 후 다시 로그인하세요.',
  CHECKPRICE: '가격표를 확인해주세요',
  KINDCODE: 'kindCode missing',
  ERROR: '오류입니다. 관리자에게 문의하세요.',
};

const ERROR = {
  CLIENT: '클라이언트 오류입니다. 관리자에게 문의하세요.',
  SERVER: `서버에 문제가 있습니다. 관리자에게 문의하세요.`,
};
/**
 * 콘솔에서 띄우는 text
 */
const LOG = {
  DATAGET: '데이터 로드에 실패 했습니다.',
  DATAPOST:
    '데이터 업로드에 실패 하였습니다. 정상적으로 로그인했는지 확인하시고 관리자에게 문의하세요.',
};
export { MESSAGE, LOG, CONFIRM, ERROR };
