import { useState, useEffect } from 'react';
import useFetch from '@utils/customHook/useFetch';
import moment from 'moment';
import axios from 'axios';
import { SERVER_URL } from '@config/path';
import { FirstInput, ProductListType } from '@interfaces/tableCost.interfaces';
import EditModal from '@components/modal/editModal';
import EditModalCalc from '@components/modal/editModal-calc';
import PreviewModal from '@components/modal/previewModal';
import DeleteModal from '@components/modal/deleteModal';
import { basicConst } from '@dummydata/dummy';
import { ProductCalcTypes } from '@dummydata/productCalcTypes';
import { useAuthState } from '@config/context';
import { CONFIRM, MESSAGE } from '@utils/textMessage';
import 'dayjs/locale/ko';
import { useLoading } from '@config/loadingContext';
import { ExcelDownload } from '@components/excel/download';
export default function Standardproduct() {
  moment.locale();
  /** 로딩 state*/
  const { loadingNow, loadingHandler } = useLoading();
  const { payload, error } = useFetch<ProductListType>(`product`, loadingNow);
  /**S:제품필터 */
  const [productList, setProductList] = useState<ProductListType[]>([]);
  const [typeFilter, setTypeFilter] = useState<string[]>();
  const [typeFilterNow, setTypeFilterNow] = useState<string>('전체');
  const [productFilter, setProductFilter] = useState<string[]>();
  const [productFilterNow, setProductFilterNow] = useState<string>('전체');
  useEffect(() => {
    if (payload) {
      let copy = [...payload.data];
      let filt = copy
        .filter((a) => a.category[0].categoryName1st)
        .map((b) => {
          return b.category[0].categoryName1st;
        })
        .filter(
          (thing: string, index: number, self: string[]) =>
            index === self.findIndex((t) => t === thing),
        );
      setProductFilter(filt);
      let typefilt = copy
        .filter((a) => a.typeOfPriceDetermintion)
        .map((b) => {
          return b.typeOfPriceDetermintion;
        })
        .filter(
          (thing: string, index: number, self: string[]) =>
            index === self.findIndex((t) => t === thing),
        );
      setTypeFilter(typefilt);
      let dataA = changeProductFilter('type', typeFilterNow, copy);
      let dataB = changeProductFilter('group', productFilterNow, dataA);
      nsortBy(nsortNow.type, nsortNow.key, dataB);
    } else {
      console.log('payload yet!');
    }
  }, [payload]);
  const changeProductFilter = (
    kind: string,
    value: string,
    data: ProductListType[],
  ) => {
    let typeNow = kind == 'type' ? value : typeFilterNow;
    let groupNow = kind == 'group' ? value : productFilterNow;
    let copy = [...data];
    if (kind === 'type') {
      copy = copy.filter((a) =>
        typeNow !== '전체' ? a.typeOfPriceDetermintion === typeNow : a,
      );
      setProductList(copy);
      let filt = copy
        .filter((a) => a.category[0].categoryName1st)
        .map((b) => {
          return b.category[0].categoryName1st;
        })
        .filter(
          (thing: string, index: number, self: string[]) =>
            index === self.findIndex((t) => t === thing),
        );
      setProductFilter(filt);
      setTypeFilterNow(typeNow);
      setProductFilterNow('전체');
    } else {
      copy = copy
        .filter((a) =>
          groupNow !== '전체' ? a.category[0].categoryName1st == groupNow : a,
        )
        .filter((a) =>
          typeNow !== '전체' ? a.typeOfPriceDetermintion === typeNow : a,
        );
      setProductList(copy);
      setProductFilterNow(groupNow);
    }
    return copy;
  };
  /**E:제품필터 */
  /**
   * 리스트 클릭이벤트 state
   * @param login boolean
   */
  /** 모달 state*/
  const [modal, setModal] = useState<{ [index: string]: boolean }>({
    price: false,
    calc: false,
    preview: false,
    delete: false,
  });
  const changeModal = (key: string, bool: boolean) => {
    setModal({ ...modal, [key]: bool });
  };
  /**미리보기모달 state*/
  const [previewName, setPreviewName] = useState<string>('');
  const previewhandler = (bool: boolean) =>
    setModal({ ...modal, preview: bool });
  /**선택한 메인 state*/
  let basicCopy = JSON.parse(JSON.stringify(basicConst));
  let originCopy = JSON.parse(JSON.stringify(basicConst));
  const [firstInputs, setFirstInputs] = useState<FirstInput>({ ...basicCopy });
  /**변경시 백업 state*/
  const [originInputs, setOriginInputs] = useState<FirstInput>({
    ...originCopy,
  });
  /**
   * 기준 상품 프리뷰 오픈
   * @param productID
   */
  const perviewEvents = (productID: string) => {
    loadingHandler(true);
    setPreviewName(productID);
    previewhandler(true);
  };
  /**
   * 기준 상품 수정하는 함수
   * @param productNameEN
   */
  const editEvents = async (productNameEN: string) => {
    loadingHandler(true);
    await axios
      .get(`${SERVER_URL}/product/${productNameEN}`)
      .then((res) => {
        setFirstInputs(res.data); //데이터 업데이트
        changeModal('price', true);
        console.log('오리지날 데이터받기');
        let Copy = { ...res.data };
        let NewCopy = JSON.parse(JSON.stringify(Copy));
        setPreviewName(productNameEN);
        setOriginInputs(NewCopy);
        loadingHandler(false);
      })
      .catch((err) => {
        console.log(err);
        loadingHandler(false);
      });
  };

  /** 계산식용 ID state */
  const [productName, setProductName] = useState<string>('');
  const [calcProductId, setCalcProductId] = useState<string>('');

  /**
   * 계산형 기준 상품 수정하는 함수
   * @param productID 선택제품아이디
   * @param productName 선택제품명
   */

  const editCalc = (productID: string, productName: string) => {
    loadingHandler(true);
    setCalcProductId(productID);
    setProductName(productName);
    changeModal('calc', true);
    loadingHandler(false);
  };

  const [delID, setDelID] = useState<string>();
  const openDelConfirm = (id: string) => {
    setDelID(id);
    changeModal('delete', true);
  };

  /**
   * 기준 상품 삭제하는 함수
   * @param row: db id 값
   */

  const delEvents = async (id: string) => {
    loadingHandler(true);
    if (confirm(CONFIRM.DEL)) {
      await axios
        .delete(`${SERVER_URL}/product/${id}`)
        .then((res) => {
          res.data == 'OK' ? alert(MESSAGE.DELDONE) : alert(MESSAGE.NOTDEL);
          setDelID(undefined);
          loadingHandler(false);
        })
        .catch((err) => {
          alert(MESSAGE.CONFIRMAUTH);
          console.log(err);
          loadingHandler(false);
        });
    }
  };

  /**
   * 기준 상품 숨기기 / 보이기
   * @param row: db id 값
   */
  const hideEvents = async (id: string, bool: boolean) => {
    loadingHandler(true);
    confirm(CONFIRM.HIDE)
      ? await axios
          .put(`${SERVER_URL}/product/${id}?isExposure=${bool}`)
          .then((res) => {
            res.data.result == 'OK'
              ? alert(MESSAGE.SAVE)
              : alert(MESSAGE.ERROR);
            loadingHandler(false);
          })
          .catch((err) => {
            alert(MESSAGE.CONFIRMAUTH);
            console.log(err);
            loadingHandler(false);
          })
      : '';
  };

  /** 테이블 sort 화살표 컬러 */
  const [sortColor, setSortColor] = useState<string>('');

  /**테이블 Sort State */

  const [nsortNow, setNsortNow] = useState<{ type: boolean; key: string }>({
    type: false,
    key: 'createdAt',
  });

  /**테이블 Sort Action */

  const [sortselect, setsortselect] = useState<boolean>(true);

  const nsortBy = (type: boolean, key: string, data: ProductListType[]) => {
    setsortselect(!sortselect);
    setSortColor(String([key]));
    let copy = [...data];
    if (key !== 'categoryName1st') {
      copy.sort((a, b) => {
        if (!type) {
          if (
            /^[ㄱ-ㅎㅏ-ㅣ가-힣]/.test(String(a[key])) &&
            /^[a-zA-Z]/.test(String(b[key]))
          ) {
            return -1;
          }
          if (
            /^[a-zA-Z]/.test(String(a[key])) &&
            /^[ㄱ-ㅎㅏ-ㅣ가-힣]/.test(String(b[key]))
          ) {
            return 1;
          }
          return String(a[key]) < String(b[key]) ? -1 : 1;
        } else {
          if (
            /^[ㄱ-ㅎㅏ-ㅣ가-힣]/.test(String(a[key])) &&
            /^[a-zA-Z]/.test(String(b[key]))
          ) {
            return -1;
          }
          if (
            /^[a-zA-Z]/.test(String(a[key])) &&
            /^[ㄱ-ㅎㅏ-ㅣ가-힣]/.test(String(b[key]))
          ) {
            return 1;
          }
          return String(a[key]) > String(b[key]) ? -1 : 1;
        }
      });
    } else {
      copy.sort((a, b) =>
        !type
          ? a.category[0].categoryName1st < b.category[0].categoryName1st
            ? -1
            : 1
          : a.category[0].categoryName1st > b.category[0].categoryName1st
          ? -1
          : 1,
      );
    }
    setProductList(copy);
    setNsortNow({ ...nsortNow, type: type, key: key });
  };

  /** 텍스트 복사 */

  const handleCopyClipBoard = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
      alert('클립보드에 링크가 복사되었습니다.');
    } catch (e) {
      alert('복사에 실패하였습니다');
    }
  };

  return (
    <>
      <div className="fixedContainer bg-f7f7f7">
        <div className="page-title">
          <div className="title_left">
            <h3>
              <i className="fa fa-cube" />
              기준 상품리스트 <small>기준 상품</small>
            </h3>
          </div>
        </div>
        <div className="clearfix" />
        {payload && (
          <div className="row">
            <div className="col-12">
              <div className="contents-wrap x_panel mb-2">
                <div className="groupFilter">
                  <h5>가격표타입</h5>
                  <div className="groupFilter-wrap">
                    <div className="groupFilter-inner">
                      <div
                        className={`groupFilter-item ${
                          typeFilterNow == '전체' ? 'active' : ''
                        }`}
                        onClick={() =>
                          changeProductFilter('type', '전체', payload.data)
                        }
                      >
                        전체
                      </div>
                      {typeFilter?.map((filt) => {
                        return (
                          <div
                            key={filt}
                            className={`groupFilter-item ${
                              typeFilterNow == filt ? 'active' : ''
                            }`}
                            onClick={() =>
                              changeProductFilter('type', filt, payload.data)
                            }
                          >
                            {filt == 'priceComplete' ? '완성형' : '계산형'}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
                <div className="groupFilter">
                  <h5>제품그룹</h5>
                  <div className="groupFilter-wrap">
                    <div className="groupFilter-inner">
                      <div
                        className={`groupFilter-item ${
                          productFilterNow == '전체' ? 'active' : ''
                        }`}
                        onClick={() =>
                          changeProductFilter('group', '전체', payload.data)
                        }
                      >
                        전체
                      </div>
                      {productFilter?.map((filt) => {
                        return (
                          <div
                            key={filt}
                            className={`groupFilter-item ${
                              productFilterNow == filt ? 'active' : ''
                            }`}
                            onClick={() =>
                              changeProductFilter('group', filt, payload.data)
                            }
                          >
                            {filt}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
              <div className="clearfix"></div>
              <div className="contents-wrap x_panel">
                <div className="x_content priceList-table-wrap">
                  <div className="react-bootstrap-table">
                    <table className="table table-hover table-bordered">
                      <thead>
                        <tr>
                          <th
                            className={`sortable ${
                              sortColor === 'typeOfPriceDetermintion'
                                ? 'colorOn'
                                : ''
                            }`}
                            onClick={() => {
                              nsortBy(
                                !sortselect,
                                'typeOfPriceDetermintion',
                                productList,
                              );
                            }}
                          >
                            가격표{' '}
                            <span
                              className={`order-4 ${
                                nsortNow.type === false ? 'setcolor' : 'nocolor'
                              }`}
                            ></span>
                          </th>
                          <th
                            className={`sortable ${
                              sortColor === 'categoryName1st' ? 'colorOn' : ''
                            }`}
                            onClick={() => {
                              nsortBy(
                                !sortselect,
                                'categoryName1st',
                                productList,
                              );
                            }}
                          >
                            분류{' '}
                            <span
                              className={`order-4 ${
                                nsortNow.type === false ? 'setcolor' : 'nocolor'
                              }`}
                            ></span>
                          </th>
                          <th
                            className={`sortable ${
                              sortColor === 'productNameKO' ? 'colorOn' : ''
                            }`}
                            onClick={() => {
                              nsortBy(
                                !sortselect,
                                'productNameKO',
                                productList,
                              );
                            }}
                          >
                            제품명{' '}
                            <span
                              className={`order-4 ${
                                nsortNow.type === false ? 'setcolor' : 'nocolor'
                              }`}
                            ></span>
                          </th>
                          <th className="">코드 보기</th>
                          <th
                            className={`sortable ${
                              sortColor === 'createdAt' ? 'colorOn' : ''
                            }`}
                            onClick={() => {
                              nsortBy(!sortselect, 'createdAt', productList);
                            }}
                          >
                            생성/수정
                            <span
                              className={`order-4 ${
                                nsortNow.type === false ? 'setcolor' : 'nocolor'
                              }`}
                            ></span>
                          </th>
                          <th className="">ACTION</th>
                        </tr>
                      </thead>
                      <tbody>
                        {productList &&
                          productList.map((code, idx) => {
                            let time = moment(code.createdAt).format(
                              'YYYY-MM-DD (HH:mm)',
                            );
                            let time2 = moment(code.modifiedAt).format(
                              'YYYY-MM-DD (HH:mm)',
                            );
                            return (
                              <tr key={idx}>
                                <td>
                                  {code.typeOfPriceDetermintion ==
                                  'priceComplete'
                                    ? '완성형'
                                    : '계산형'}
                                </td>
                                <td>
                                  {code.category[0].categoryName1st +
                                    ' / ' +
                                    code.category[0].categoryName2nd}
                                </td>
                                <td>
                                  {code.productNameKO +
                                    ' (' +
                                    code.productNameEN +
                                    ')'}
                                </td>
                                <td>
                                  <div className="editDate-wrap editCode-wrap">
                                    <div className="editDate-btn">보기</div>
                                    <div className="editDate-view">
                                      <div className="editDate-line">
                                        <h5>MP코드 :</h5>
                                        <div
                                          className="editData-copy"
                                          onClick={() => {
                                            handleCopyClipBoard(
                                              code.category[0].categoryName3rd,
                                            );
                                          }}
                                        >
                                          <p className="standardproductID editCod-Id">
                                            {code.category[0].categoryName3rd}
                                          </p>
                                          <p className="standardproductTIME">
                                            복사
                                          </p>
                                        </div>
                                      </div>
                                      <div className="editDate-line">
                                        <h5>제품ID :</h5>
                                        <div
                                          className="editData-copy"
                                          onClick={() => {
                                            handleCopyClipBoard(code._id);
                                          }}
                                        >
                                          <p className="standardproductID editCod-Id">
                                            {code._id}
                                          </p>
                                          <p className="standardproductTIME">
                                            복사
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <div className="editDate-wrap">
                                    <div className="editDate-btn">보기</div>
                                    <div className="editDate-view">
                                      <div className="editDate-line">
                                        <h5>생성:</h5>
                                        <div className="crmo_wrap">
                                          <p className="standardproductID">
                                            {code.createdBy}
                                          </p>
                                          <p className="standardproductTIME">
                                            {code.createdAt === undefined ? (
                                              <p>-</p>
                                            ) : (
                                              <>{time}</>
                                            )}
                                          </p>
                                        </div>
                                      </div>
                                      <div className="editDate-line">
                                        <h5>수정:</h5>
                                        <div className="crmo_wrap">
                                          <p className="standardproductID">
                                            {code.modifiedBy}
                                          </p>
                                          <p className="standardproductTIME">
                                            {time2}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  {code.typeOfPriceDetermintion ===
                                  'priceComplete' ? (
                                    <button className="tableBtn tableBtn-green btnW-80">
                                      <ExcelDownload
                                        id={code._id}
                                        producttype={'기준상품'}
                                        category={
                                          code.category[0].categoryName3rd
                                        }
                                        productName={code.productNameKO}
                                      />
                                    </button>
                                  ) : (
                                    <button className="tableBtn tableBtn-green btnW-80 disabled">
                                      엑셀다운
                                    </button>
                                  )}
                                  <button
                                    className="tableBtn tableBtn-skyblue btnW-70"
                                    onClick={() => perviewEvents(code._id)}
                                  >
                                    미리보기
                                  </button>
                                  {code.typeOfPriceDetermintion ===
                                  'priceComplete' ? (
                                    <button
                                      className="tableBtn tableBtn-orange btnW-70"
                                      onClick={() => editEvents(code._id)}
                                    >
                                      수정
                                    </button>
                                  ) : (
                                    <div className="calcbtn-wrap">
                                      <button className="tableBtn tableBtn-pink btnW-70">
                                        계산식
                                      </button>
                                      <div className="calcSmModal">
                                        <button
                                          className="tableBtn tableBtn-gray btnW-70"
                                          onClick={() => editEvents(code._id)}
                                        >
                                          옵션 수정
                                        </button>
                                        <button
                                          className="tableBtn tableBtn-gray btnW-70 me-0"
                                          onClick={() =>
                                            editCalc(
                                              code._id,
                                              code.productNameKO,
                                            )
                                          }
                                        >
                                          계산식 수정
                                        </button>
                                      </div>
                                    </div>
                                  )}
                                  <button
                                    className="tableBtn tableBtn-red btnW-70"
                                    onClick={() => openDelConfirm(code._id)}
                                  >
                                    삭제
                                  </button>
                                  {code.isExposure !== undefined && (
                                    <>
                                      {code.isExposure === true ? (
                                        <button
                                          className="tableBtn tableBtn-gray btnW-70"
                                          onClick={() =>
                                            hideEvents(code._id, false)
                                          }
                                        >
                                          숨기기
                                        </button>
                                      ) : (
                                        <button
                                          className="tableBtn tableBtn-purple btnW-70"
                                          onClick={() =>
                                            hideEvents(code._id, true)
                                          }
                                        >
                                          보이기
                                        </button>
                                      )}
                                    </>
                                  )}
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {modal.price && (
        <EditModal
          firstInputs={firstInputs}
          setFirstInputs={setFirstInputs}
          originInputs={originInputs}
          setOriginInputs={setOriginInputs}
          changeModal={changeModal}
        />
      )}
      {modal.calc && (
        <EditModalCalc
          productName={productName}
          productList={productList}
          calcProductId={calcProductId}
          changeModal={changeModal}
        />
      )}
      {modal.preview && (
        <PreviewModal
          from="standard"
          previewProduct={previewName}
          previewhandler={previewhandler}
        />
      )}
      {modal.delete && delID && (
        <DeleteModal
          delID={delID}
          delEvents={delEvents}
          changeModal={changeModal}
        />
      )}
    </>
  );
}
