import { useState, useRef, useEffect } from 'react';
import { IFRAME_URL } from '@config/path';
import { useLoading } from '@config/loadingContext';
interface Props {
  from: string;
  previewProduct?: string;
  previewhandler: (boolean: boolean) => void;
}
export default function PreviewModal({
  from,
  previewProduct,
  previewhandler,
}: Props) {
  const { loadingNow, loadingHandler } = useLoading();

  const ref = useRef<HTMLIFrameElement>(null);
  const errorhandler = () => loadingHandler(false);

  return (
    <>
      <div
        className="modalY-overlay"
        onClick={() => previewhandler(false)}
      ></div>
      <div className="previewY preview-window preview-600">
        <div className="closeBtn" onClick={() => previewhandler(false)}>
          <i className="fa fa-times"></i>
        </div>
        {previewProduct && (
          <iframe
            ref={ref}
            // loading="lazy"
            src={`${IFRAME_URL}/${
              from === 'standard' ? 'previewStandard' : 'previewSite'
            }/${previewProduct}`}
            id="myFrame"
            width="100%"
            height="100%"
            style={{
              background: '#f3f3f3',
              minHeight: 700,
              overflow: 'auto',
            }}
            sandbox="allow-scripts allow-same-origin"
            onLoad={errorhandler}
          >
            iframe를 지원하지 않는 브라우저인 경우 대체정보를 제공
          </iframe>
        )}
      </div>
    </>
  );
}
