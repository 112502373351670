import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Pagination from 'react-js-pagination';
import React, { useEffect, useState } from 'react';
import { logClient } from '@api/log/log.client';
import { useLoading } from '@config/loadingContext';
import axios from 'axios';
import { SERVER_URL } from '@config/path';
import useFetch from '@utils/customHook/useFetch';
interface Itest {
  name: string;
}
export default function Test() {
  const { loadingNow, loadingHandler } = useLoading();

  /** 계산정보 get */
  const { payload, loading, error } = useFetch<Itest>(`test`, loadingNow);
  const [nameNow, setNameNow] = useState<string>('');
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setNameNow(value);
  };
  /**
   * 저장, 완료 Action
   * @constant firstInput
   * @constant priceStep
   */
  const completeName = async () => {
    loadingHandler(true);
    await axios
      .put(SERVER_URL + '/testNow/', {
        name: nameNow,
      })
      .then(function (response) {
        loadingHandler(false);
      })
      .catch(function (error) {
        console.log('데이터 업로드에 실패 하였습니다.');
        loadingHandler(false);
      });
  };

  return (
    <>
      <div className="page-title">
        <div className="title_left">
          <h3>
            <i className="fa fa-reddit-alien" /> 테스트
          </h3>
        </div>
      </div>
      <div className="clearfix"></div>
      <div className="row">
        <div className="col-12">
          <div className="contents-wrap x_panel">
            <div className="x_title">
              <h4>
                테스트 <small>Test Table</small>
              </h4>
            </div>

            <div className="clearfix"></div>
            <div className="x_content">
              <input
                type="text"
                name="title"
                value={nameNow}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleChange(e)
                }
              />
              <div className="nameChangerButton">
                <div
                  className="tableBtn tableBtn-green"
                  onClick={() => completeName()}
                >
                  확인
                </div>
              </div>
              <div className="result">
                <h5>결과:</h5>
                {payload && payload.data.length > 0 && (
                  <div>
                    {payload.data.map((a) => {
                      return <span>{a.name}</span>;
                    })}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
